import { Directive, ElementRef, inject, input, OnDestroy, OnInit, output } from '@angular/core';
import { getScrollParent } from '@qntm-code/utils';
import { filter, fromEvent, Subscription } from 'rxjs';
import { ScrolledToDestination } from './scrolled-to-destination.model';

@Directive({
  selector: '[gridUiScrolledTo]',
})
export class ScrolledToDirective implements OnInit, OnDestroy {
  private readonly elementRef = inject(ElementRef);
  protected readonly subscriptions = new Subscription();
  private scrollParent: HTMLElement;

  /**
   * Whether to emit for scrolling to the top or bottom of the scrollable container.
   */
  public readonly destination = input.required<ScrolledToDestination>({ alias: 'gridUiScrolledTo' });

  /**
   * The offset from the top or bottom of the scrollable container to emit the event.
   */
  public readonly offset = input<number>(0, { alias: 'gridUiScrolledToOffset' });

  /**
   * Emits when the scrollable container is scrolled to the configured destination and offset.
   */
  public readonly scrolledTo = output<void>({ alias: 'gridUiScrolledTo' });

  public ngOnInit(): void {
    this.scrollParent = getScrollParent(this.elementRef.nativeElement);

    if (!this.scrollParent) {
      console.error('Could not find scroll parent for element', this.elementRef.nativeElement);
      throw new Error('Could not find scroll parent for element');
    }

    this.subscriptions.add(
      fromEvent(this.scrollParent, 'scroll')
        .pipe(
          filter(() => {
            const y = this.scrollParent.scrollTop;

            if (this.destination() === 'bottom') {
              const scrollHeight = this.scrollParent.scrollHeight - this.scrollParent.offsetHeight - this.offset();

              if (y >= scrollHeight) {
                return true;
              }
            } else if (y <= this.offset()) {
              return true;
            }

            return false;
          }),
        )
        .subscribe(() => this.scrolledTo.emit()),
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
