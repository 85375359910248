import { APP_BASE_HREF, PlatformLocation } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi, withXsrfConfiguration } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import {
  GlobalLegendModule,
  HttpErrorInterceptor,
  HttpHeadersInterceptor,
  IconModule,
  NgbTooltipConfigService,
  SearchableDropdownModule,
  ToastModule,
} from '@grid-ui/common';
import { environment, ENVIRONMENT_CONFIG } from '@grid-ui/environment';
import {
  FEATURE_FLAG_RETRIES,
  FEATURE_FLAG_URL,
  FEATURE_FLAGS_CONFIG,
  FeatureFlagModule,
  featureFlagsConfig,
  FeatureFlagService,
} from '@grid-ui/feature-flag';
import { GlobalAnnouncementsModule } from '@grid-ui/global-announcements';
import { GlobalRoutingModule } from '@grid-ui/global-routing';
import { LoggingService, SentryErrorHandler } from '@grid-ui/logging';
import { MapsService } from '@grid-ui/maps';
import { IGtmConfigProvider, UserAnalyticsModule } from '@grid-ui/user-analytics';
import { WhoAmIEffects } from '@grid-ui/whoami';
import { EffectsModule } from '@ngrx/effects';
import { Store, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { HorizontalButtonTabsModule } from '../app/shared-ui-components/horizontal-tabs/horizontal-tabs.module';
import { GtmConfigProviderService } from './analytics';
import { AppConfigModule, appInitializerFactory } from './app-config';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule, coreReducers } from './core';
import { ExtractManagementModule, PollingModule } from './extract-management';
import { GridComponent } from './grid/grid.component';
import { HomeModule } from './home';
import { PrivacySettingsModule } from './privacy-settings';
import { PageNotFoundModule } from './shared-ui-components/page-not-found';

const effectsForRoot = [WhoAmIEffects];

@NgModule({
  declarations: [AppComponent, GridComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    environment.test ? NoopAnimationsModule : BrowserAnimationsModule,
    RouterModule,
    StoreModule.forRoot(coreReducers, {
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    EffectsModule.forRoot(effectsForRoot),
    environment.production ? [] : StoreDevtoolsModule.instrument({ connectInZone: true }),
    AppConfigModule,
    CoreModule,
    HomeModule,
    PageNotFoundModule,
    GlobalAnnouncementsModule,
    GlobalRoutingModule,
    GlobalLegendModule,
    PollingModule,
    PrivacySettingsModule,
    AppRoutingModule,
    ExtractManagementModule,
    UserAnalyticsModule,
    ToastModule,
    SearchableDropdownModule,
    IconModule,
    HorizontalButtonTabsModule,
    FeatureFlagModule,
  ],
  providers: [
    { provide: ENVIRONMENT_CONFIG, useValue: environment },
    {
      provide: FEATURE_FLAG_URL,
      useValue: '/fe-api/feature-flag/',
    },
    {
      provide: FEATURE_FLAG_RETRIES,
      useValue: 2,
    },
    {
      provide: FEATURE_FLAGS_CONFIG,
      useValue: featureFlagsConfig,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [LoggingService, MapsService, FeatureFlagService, NgbTooltipConfigService, Store],
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: SentryErrorHandler,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpHeadersInterceptor,
      multi: true,
    },
    {
      provide: APP_BASE_HREF,
      useFactory: (platformLocation: PlatformLocation) => platformLocation.getBaseHrefFromDOM(),
      deps: [PlatformLocation],
    },
    { provide: IGtmConfigProvider, useClass: GtmConfigProviderService },
    provideHttpClient(
      withInterceptorsFromDi(),
      withXsrfConfiguration({
        cookieName: environment.csrfToken,
        headerName: 'X-CSRFToken',
      }),
    ),
  ],
})
export class AppModule {}
