<span
  *ngIf="!cellValue?.data.restricted"
  #indexInfoIcon
  class="grid-ui-index-info-icon"
  [class.mc-active]="hover"
  [class.mc-hover]="hover"
  (click)="clickInfoIcon()"
  (mouseenter)="hover = true"
  (mouseleave)="hover = false"
  data-hook="index-info-icon-span"
>
  <span placement="top" [ngbTooltip]="'Show details'" [disableTooltip]="disabled">
    <grid-ui-icon class="info-icon" name="info-circle" data-hook="info-icon-image"></grid-ui-icon>
  </span>
</span>
