import { NgbTooltipConfigService } from '@grid-ui/common';
import { environment } from '@grid-ui/environment';
import { FeatureFlagService } from '@grid-ui/feature-flag';
import { LoggingService } from '@grid-ui/logging';
import { MapsService } from '@grid-ui/maps';
import { appendGoogleTagManagerScript, appendHotjarScript } from '@grid-ui/user-analytics';
import { selectLoggedIn } from '@grid-ui/whoami';
import { Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';
import { filter, switchMap, take } from 'rxjs/operators';

export function appInitializerFactory(
  loggingService: LoggingService,
  mapsService: MapsService,
  featureFlagService: FeatureFlagService,
  tooltipConfigService: NgbTooltipConfigService,
  store: Store,
): () => Promise<any> {
  loggingService.initialise(environment.appConfig.sentryDsn);
  tooltipConfigService.initialise();

  if (environment.appConfig.hotjarTrackingCode) {
    appendHotjarScript(environment.appConfig.hotjarTrackingCode);
  }

  if (environment.googleTagManagerId) {
    appendGoogleTagManagerScript(environment.googleTagManagerId);
  }

  // Authenticate the maps service early once logged in
  void firstValueFrom(
    store.select(selectLoggedIn).pipe(
      filter((loggedIn) => loggedIn),
      take(1),
      switchMap(() => mapsService.authenticate()),
    ),
  );

  return () =>
    featureFlagService.initialise().catch((error) => {
      console.error('An error occurred during the Angular App initialization process: ', error);
      throw error;
    });
}
